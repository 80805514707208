import { isBoolean } from 'util';
import {
	excludingMsg,
	htaMsgVerification,
	diabetesMsgSupport
} from 'components/utils/HealthMessages';
import { _calculateAge } from 'components/utils/CalculateAge';

export const surveysCompleted = (answers, show) => {
	/*** OPTIMIZAR FUNCION */
	let incomplete = undefined;
	const showSub = show?.toString();

	for (const answer of answers) {
		if (answer.case === undefined) {
			// if (answer.isSelectMultiple && (!answer.selectedIndex || (answer.selectedIndex && answer.selectedIndex.length === 0))) {
			if (answer.isSelectMultiple && answer.surveys?.length > 0) {
				const sur_incomplete = surveysCompleted(answer.surveys, answer.showSub);
				if (sur_incomplete) {
					incomplete = sur_incomplete;
					break;
				}
			}

			if (answer.isRange && answer.valueInRange === undefined) {
				incomplete = true;
				break;
			} else if (answer.valueInRange && answer.surveys?.length > 0) {
				const sur_incomplete = surveysCompleted(answer.surveys, answer.showSub);
				if (sur_incomplete) {
					incomplete = sur_incomplete;
					break;
				}
			}

			if (answer.isBoolean && typeof answer.value !== 'boolean') {
				incomplete = true;
				break;
			} else if (
				typeof answer.value === 'boolean' &&
				answer.validValue !== answer.value &&
				answer.surveys?.length > 0
			) {
				const sur_incomplete = surveysCompleted(answer.surveys, answer.showSub);
				if (sur_incomplete) {
					incomplete = sur_incomplete;
					break;
				}
			}

			if (answer.isString && answer.value_string === undefined) {
				incomplete = true;
				break;
			} else if (answer.value_string && answer.surveys?.length > 0) {
				const sur_incomplete = surveysCompleted(answer.surveys, answer.showSub);
				if (sur_incomplete) {
					incomplete = sur_incomplete;
					break;
				}
			}
		} else {
			const sur_incomplete = surveysCompleted(answer.surveys, showSub);
			if (answer.case.toString() === showSub) {
				if (sur_incomplete) {
					incomplete = sur_incomplete;
					break;
				}
			}
		}
	}
	return incomplete === undefined ? false : incomplete;
};

export const handleHealthWaning = (answers) => {
	return new Promise(async (resolve, reject) => {
		await Promise.all(
			answers.map(async (answer) => {
				if (answer.case === undefined) {
					if (
						isBoolean(answer.value) &&
						answer.value !== answer.validValue &&
						answer.excluding === true
					) {
						return resolve(true);
					} else if (
						isBoolean(answer.value) &&
						answer.value !== answer.validValue &&
						Array.isArray(answer.surveys)
					) {
						const response = await handleHealthWaning(answer.surveys);
						if (response === true) return resolve(true);
					}
				} else {
					const response = await handleHealthWaning(answer.surveys);
					if (response === true) return resolve(true);
				}
			})
		);
		return resolve(false);
	});
};

export const getCondition = async (
	{ body_mass, birth_date },
	arr,
	incomingMessage = [],
	incomingVerification = false,
	incomingShow
) => {
	let message = incomingMessage;
	let requireVerification = incomingVerification;
	let show = incomingShow?.toString();

	for (const a of arr) {
		const {
			isSelectMultiple,
			selectedIndex,
			maxCantSelected,
			requireMedicalVerification,
			isRange,
			valueInRange,
			minValue,
			maxValue,
			value,
			validValue,
			excluding,
			surveys,
			showSub,
			isString,
			value_string
		} = a;

		// Validaciones excluyentes
		if (
			_calculateAge(birth_date) > 80 ||
			(_calculateAge(birth_date) > 16 &&
				(body_mass <= 18.5 || body_mass >= 35)) ||
			(a.isBoolean && Boolean(value) && value !== validValue && excluding) ||
			(isSelectMultiple && (!selectedIndex || selectedIndex?.length === 0)) ||
			(isRange && parseFloat(valueInRange) > maxValue)
		) {
			requireVerification = true;
			message = [
				{
					message: excludingMsg,
					status: 'info'
				}
			];
			break;
		}

		// Validaciones de segunda verificacion
		if (
			(isSelectMultiple &&
				selectedIndex?.length >= 3 &&
				requireMedicalVerification) ||
			(isString && value_string && requireMedicalVerification)
		) {
			requireVerification = false;
			message = [
				{
					message: htaMsgVerification,
					status: 'warning'
				}
			];
		}

		// Validaciones de soporte
		if (
			isRange &&
			parseFloat(valueInRange) > minValue &&
			parseFloat(valueInRange) <= maxValue
		) {
			requireVerification = false;
			message = [
				{
					message: diabetesMsgSupport,
					status: 'done'
				}
			];
		}

		if (surveys?.length > 0 && show === a.case) {
			const response = await getCondition(
				{ body_mass, birth_date },
				surveys,
				message,
				requireVerification,
				showSub
			);
			if (response.condition.length > 0) {
				message = response.condition;
				requireVerification = response.verification;
				break;
			}
		}
	}
	return {
		condition: message.filter(
			(item, pos, self) =>
				self.findIndex((s) => s.message === item.message) === pos
		), // Remove duplicates
		verification: requireVerification
	};
};

export const renderAnswers = (answers, isSub) => (
	<>
		{answers?.map((answer, i) => (
			<div
				className={`py-4 ${isSub ? 'mx-3' : 'border-t border-gray-300'}`}
				key={i}
			>
				<div
					key={i}
					className={`flex text-gray-800 flex-wrap flex-col wInputFull mt-0 ${
						isSub ? 'ml-3' : ''
					}`}
				>
					{!answer.isBoolean &&
					!answer.isRange &&
					!answer.isSelectMultiple &&
					!answer.isString ? (
						<>
							<span className="font-bold">{answer.name}:&nbsp;</span>
							{answer?.help && (
								<span className="text-sm my-2 text-gray-800 bg-purple-200">
									{answer?.help}
								</span>
							)}
							<span>{answer.value ? 'Si' : 'No'}</span>
						</>
					) : (
						<>
							{answer.isBoolean && (
								<>
									<span className="font-bold">{answer.name}:&nbsp;</span>
									{answer?.help && (
										<span className="text-sm my-2 text-gray-800 bg-purple-200 p-3">
											{answer?.help}
										</span>
									)}
									<span>{answer.value ? 'Si' : 'No'}</span>
								</>
							)}
							{answer.isRange && (
								<>
									<span className="font-bold">{answer.name}:&nbsp;</span>
									{answer?.help && (
										<span className="text-sm my-2 text-gray-800 bg-purple-200 p-3">
											{answer?.help}
										</span>
									)}
									<span>{answer.valueInRange}</span>
								</>
							)}
							{answer.isSelectMultiple && (
								<>
									<span className="font-bold">{answer.name}:&nbsp;</span>
									{answer?.help && (
										<span className="text-sm my-2 text-gray-800 bg-purple-200 p-3">
											{answer?.help}
										</span>
									)}
									<span>{answer.selectedIndex?.join(', ')}</span>
								</>
							)}
							{answer.isString && (
								<>
									<span className="font-bold">{answer.name}:&nbsp;</span>
									{answer?.help && (
										<span className="text-sm my-2 text-gray-800 bg-purple-200 p-3">
											{answer?.help}
										</span>
									)}
									<span>{answer.value_string}</span>
								</>
							)}
						</>
					)}
				</div>
				{answer.surveys?.length > 0 &&
					renderAnswers(
						answer.surveys.some((s) => s.case)
							? answer.surveys.find(
									(s) => s?.case?.toString() === answer?.showSub?.toString()
							  )?.surveys
							: answer.surveys,
						true
					)}
			</div>
		))}
	</>
);
