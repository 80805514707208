import React, { Component } from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import { SelectCurrency } from 'components/commons';
import { formatFloat } from '../utils/formatFloat';
import TermsAddonDisplayer from '../terms/TermsAddonDisplayer';

class AddonsCard extends Component {
	state = {
		showDialog: false,
		addon: null,
		idx: null
	};

	handleAcceptTerm(addon, idx) {
		this.setState({ showDialog: true, addon, idx });
		this.setState({ addon, idx });
	}

	handleClose = (param) => {
		const { addon, idx } = this.state;
		this.setState({ showDialog: false });
		if(param) this.handleAcceptTerm(addon,idx)
		this.props.handleSelectAddon(param, addon, idx);
		this.setState({showDialog:false})
	};
	render() {
		const { user, handleSelectAddon, isoCode } = this.props;

		return (
			<>
				{this.state.showDialog &&
					<TermsAddonDisplayer 
						addon={this.state.addon} 
						handleClose={this.handleClose}
					/>
				}

				{user.listAddons &&
					user.listAddons.length > 0 &&
					user.listAddons.map(
						(addon, idx) =>
							(!addon.depends_on_health ||
								(addon.depends_on_health && user.goodHealth)) && (
								<div
									key={idx}
									className="border border-purple-300 rounded-xl bg-white w-full p-10 my-3"
								>
									<div className="flex justify-between items-center flex-wrap">
										<div className="text-3xl font-bold text-purple-500">
											{addon.name}
										</div>
										<span className="flex items-center">
											<span className="font-bold text-purple-300 text-3xl">
												{isoCode && isoCode.toUpperCase()}{' '}
												{formatFloat(
													addon.payment_methods[0].tariffs[0].currency[isoCode]
												)}
											</span>
											<span className="ml-4">
												<SelectCurrency hideFlag={true} />
											</span>
										</span>
									</div>
									<div className="flex justify-between items-center flex-wrap sm:mt-8">
										<span className="text-base leading-relaxed text-gray-800 mt-8 sm:mt-0">
											{addon.description}
										</span>
										<div className="flex justify-between">
											<FormControlLabel
												style={{ marginRight: 65 }}
												onClick={() => {
													if (!user.NotEditCustomer)
														this.handleAcceptTerm(addon, idx);
												}}
												control={
													<Radio
														checked={Boolean(
															user.addons_id &&
																user.addons_id?.some(
																	(a) => (a?.addon_id || a) == addon._id
																)
														)}
													/>
												}
												label={
													<span className={`text-gray-800 text-base font-bold`}>
														Si, lo quiero
													</span>
												}
												labelPlacement="end"
												disabled={user.NotEditCustomer}
											/>
											<FormControlLabel
												onClick={() => {
													if (!user.NotEditCustomer)
														handleSelectAddon(false, addon, idx);
												}}
												control={
													<Radio
														checked={
															!(
																user.addons_id &&
																user.addons_id?.some(
																	(a) => (a?.addon_id || a) == addon._id
																)
															)
														}
													/>
												}
												label={
													<span className={`text-gray-800 text-base font-bold`}>
														No
													</span>
												}
												labelPlacement="end"
												disabled={user.NotEditCustomer}
											/>
										</div>
									</div>
								</div>
							)
					)}
			</>
		);
	}
}

export default AddonsCard;
