import React, { Component } from 'react';
import Router from 'next/router';

import {
	TextField,
	MenuItem,
	Radio,
	Checkbox,
	FormControlLabel,
	InputAdornment,
	Tooltip
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { surveysCompleted, getCondition } from '../utils/HealthQuestions';
import ReactTelInput from 'react-telephone-input';
import Flags from '../../assets/images/flags.png';
import CountryList from '../utils/CountryList';
import { SnackBar } from '../widgets';

import Validations from '../utils/Validations';
import FileWithIconInput from '../commons/FileWithIconInput';
import {
	TextInput,
	SelectSimpleOutlined,
	DateInput,
	SelectCurrency
} from 'components/commons';
import AddonsCard from '../addons/AddonsCard';
import { getUserAddons } from '../utils/AddonUtilities';
import HealthSurvey from './HealthSurvey';
import CityList from 'components/utils/CityList';
import { CalculateBMI } from 'components/utils/CalculateBMI';
import { formatFloat } from '../utils/formatFloat';

class HeadlineForm extends Component {
	state = {
		showInfo: '',
		openAlert: false,
		messageAlert: '',
		addonsLoaded: false,
		isChangeEmail: false
	};

	handleClose = () => this.setState({ openAlert: false });

	async componentDidMount() {
		const { saveState, readOnlyPlans, is_profile, getPlansByAge } = this.props;
		let titular = this.props.titular;
		this.initAddons();
		if (!titular.formErrors) {
			if (
				(titular.include_titular === false || !titular.include_titular) &&
				!titular.city
			)
				titular.city = '';
			titular.formErrors = [];
			saveState('titular', titular);
		}
		if (
			titular &&
			titular.include_titular === true &&
			!readOnlyPlans &&
			titular.birth_date &&
			(!titular.products ||
				(titular.products && titular.products.length <= 0)) &&
			is_profile !== true
		) {
			const result = await getPlansByAge({ birth_date: titular.birth_date });
			if (result.products) titular.products = result.products;
			titular.kinship = 'TITULAR';
			saveState('titular', titular);
		}

		if (titular.product_id) {
			const productType =
				titular.product_type && typeof titular.product_type === 'string'
					? JSON.parse(titular.product_type)
					: titular.product_type;
			const findProduct = titular?.products?.find(
				(product) => product._id === productType._id
			);
			titular.product_type = productType;
			titular.questions = findProduct?.questions;
			if (
				!titular.answers ||
				(titular.answers && titular.answers.length <= 0)
			) {
				await this.handleSelectPlan(titular.product_type);
			}
			saveState('titular', titular);
		}

		this.setState({ showInfo: '' });
	}

	componentWillUnmount() {
		this.setState({ addonsLoaded: false });
	}

	getTitularHealth = async () => {
		// validacion preguntas por planes.
		const titular = this.props.titular;
		const isIncomplete = surveysCompleted(titular.answers || []);
		if (!isIncomplete) titular.complete_surveys = true;
		else titular.complete_surveys = false;
		if (titular.feet && titular.inches && titular.weight) {
			const body_mass = CalculateBMI(
				titular?.feet,
				titular?.inches,
				titular?.weight
			);
			titular.body_mass = body_mass;
			//Verifico Titular health
			const conditionResult = await getCondition(
				{ body_mass: titular.body_mass, birth_date: titular.birth_date },
				titular.answers
			);
			titular.goodHealth = conditionResult.condition.some(
				(c) => c.status === 'info'
			)
				? false
				: true;
		} else {
			titular.goodHealth = false;
		}
		this.props.saveState('titular', titular);
	};

	initAddons = async () => {
		const { stepIndex, titular, saveState, is_profile } = this.props;
		const { addonsLoaded } = this.state;

		if (stepIndex === 1 && !addonsLoaded && !is_profile) {
			const addonsArray = [];
			await this.getTitularHealth();
			this.setState({ addonsLoaded: true }, async () => {
				const addons = getUserAddons(titular);
				titular.product_type =
					titular.product_type && typeof titular.product_type === 'string'
						? JSON.parse(titular.product_type)
						: titular.product_type;

				const result = await this.props.fetchAddons(titular);
				if (result) titular.listAddons = result.addons;

				addons.forEach((a) => {
					const aIdx = titular.listAddons.findIndex(
						(ap) => ap._id === (a.addon_id || a._id)
					);
					addonsArray[aIdx] = {
						addon_id: a.addon_id || a._id,
						addon_price: a.addon_price || a.payment_methods[0].tariffs[0].price
					};
				});
				titular.addons_id = addonsArray;
				saveState('titular', titular);
			});
		} else if (stepIndex !== 1) this.setState({ addonsLoaded: true });
	};

	handleInput = async (event) => {
		const {
			is_profile,
			saveState,
			calculations,
			readOnlyPlans,
			payment,
			titular
		} = this.props;
		const { name, value } = event.target;
		titular[name] = value;

		if (
			name === 'dni' &&
			this.props.paymentHeadlineForm &&
			payment.use_titular_data
		) {
			payment.dni = value;
			this.props.saveState('payment', payment);
		}

		if (
			(name === 'city' || name === 'state') &&
			this.props.paymentHeadlineForm &&
			payment.use_titular_data
		) {
			payment.city = value;
			payment.state = value;
			this.props.saveState('payment', payment);
		}

		if (name === 'birth_date' && this.props.titular.include_titular === true) {
			titular.addons = [];
			const result = await this.props.fetchAddons(titular);
			if (result) titular.listAddons = result.addons;
		}

		if (name === 'weight' || name === 'inches' || name === 'feet') {
			titular.addons = [];
			titular.addons_id = [];
			await this.getTitularHealth();
			const result = await this.props.fetchAddons(titular);
			if (result) titular.listAddons = result.addons;
		}

		if (name === 'email') {
			this.setState({
				isChangeEmail: this.props.originalEmail !== titular.email ? true : false
			});
		}

		saveState('titular', titular);
		this.setState({ showInfo: '' });
		Validations.validateFieldHeadLine(titular, saveState, name, value);
		if (
			titular.include_titular === true &&
			is_profile !== true &&
			!readOnlyPlans
		)
			calculations();
	};

	handleInputFile = (event) => {
		const { saveState, is_profile, readOnlyPlans, calculations } = this.props;
		const { name, files } = event.target;
		const file = files[0];
		const titular = this.props.titular;
		const data = {
			body: '',
			type: file.type,
			extension: file.name.split('.').pop()
		};
		const reader = new FileReader();
		reader.onloadend = function () {
			data.body = reader.result;
		};
		titular[name] = file;
		titular.doc_dni = data;
		saveState('titular', titular);
		this.setState({ showInfo: '' });
		Validations.validateFieldHeadLine(titular, saveState, 'doc_dni', file);
		if (
			titular.include_titular === true &&
			is_profile !== true &&
			!readOnlyPlans
		)
			calculations();
		return reader.readAsDataURL(file);
	};

	handleInputCountry = (event) => {
		const { name, value } = event.target;
		let { titular, payment } = this.props;

		titular[name] = value;
		titular.city = '';
		titular.address = '';
		this.props.saveState('titular', titular);
		if (value === 'España' && this.props.paymentHeadlineForm) {
			this.props.activateEuro();
		}
		if (this.props.paymentHeadlineForm && payment.use_titular_data) {
			payment.country = value;
			payment.DIP = false;
			payment.acceptTerms = false;
			payment.zip_zone = '';
			this.props.saveState('payment', payment);
		}
		this.setState({ showInfo: '' });
		Validations.validateFieldHeadLine(
			titular,
			this.props.saveState,
			name,
			value
		);
	};

	handlePhone = (name, value) => {
		let { titular, payment } = this.props;
		titular[name] = value;
		this.props.saveState('titular', titular);
		this.setState({ showInfo: '' });
		if (this.props.paymentHeadlineForm && payment.use_titular_data) {
			payment[name] = value;
			this.props.saveState('payment', payment);
		}
		Validations.validateFieldHeadLine(
			this.props.titular,
			this.props.saveState,
			name,
			value
		);
	};

	handleInputSex = async (event) => {
		let { name, value } = event.target;
		let titular = this.props.titular;
		titular.conditions_pregnant = false;
		titular[name] = value;

		if (
			this.props.hasOwnProperty('fetchAddons') &&
			this.props.titular.include_titular === true
		) {
			titular.addons = [];
			const result = await this.props.fetchAddons(titular);
			if (result) titular.listAddons = result.addons;
		}

		this.props.saveState('titular', titular);
		this.setState({ showInfo: '' });
		Validations.validateFieldHeadLine(
			titular,
			this.props.saveState,
			name,
			value
		);
	};

	handleCheckbox = (event, param) => {
		const { name, checked } = event.target;
		const titular = this.props.titular;
		titular[name] = name === 'promo_code' ? param : checked;
		this.props.saveState('titular', titular);
		this.setState({ showInfo: '' });
		Validations.validateFieldHeadLine(
			this.props.titular,
			this.props.saveState,
			name,
			checked
		);
	};

	handleCheckboxAssistedSelling = (event, param) => {
		const { name, checked } = event.target;
		const titular = this.props.titular;
		titular[name] = name === 'assisted_selling' ? param : checked;
		this.props.saveState('titular', titular);
		this.setState({ showInfo: '' });
		Validations.validateFieldHeadLine(
			this.props.titular,
			this.props.saveState,
			name,
			checked
		);
	};

	handleBirthDate = async (date) => {
		const {
			saveState,
			is_profile,
			readOnlyPlans,
			getPlansByAge,
			calculations
		} = this.props;
		const titular = this.props.titular;
		titular.birth_date = date;
		await saveState('titular', titular);
		if (date && date instanceof Date && !isNaN(date.valueOf())) {
			const age = this._calculateAge(date);
			titular.age = age;
			if (
				titular.include_titular === true &&
				is_profile !== true &&
				!readOnlyPlans
			) {
				setTimeout(async () => {
					const result = await getPlansByAge({ age });
					if (result.products) titular.products = result.products;
					if (result.products && titular.product_id) {
						const productType = result.products.find(
							(p) => titular.product_id === p._id
						);
						await this.handleSelectPlan(productType);
					}
					if (result.success === false) this.setState({ ...result });
					saveState('titular', titular);
					this.setState({ showInfo: '' });
				}, 1);
			}
		}
		Validations.validateFieldHeadLine(titular, saveState, 'birth_date', date);
		if (
			titular.include_titular === true &&
			is_profile !== true &&
			!readOnlyPlans
		)
			calculations();
		this.setState({ showInfo: '' });
	};

	_calculateAge(birthday) {
		const today = new Date();
		const birthDate = new Date(birthday);
		let age = today.getFullYear() - birthDate.getFullYear();
		const monthToday = today.getMonth() + 1;
		const monthBirthDate = birthDate.getMonth() + 1;
		const m = monthToday - monthBirthDate;
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age -= 1;
		}
		return age;
	}

	cleanAddonsHealth = () => {
		const { titular } = this.props;
		if (!titular.goodHealth) {
			titular.addons = getUserAddons(titular);
			if (!titular.addons_id) titular.addons_id = [];
			const addonsHealth = titular.addons
				?.filter((a) => a.depends_on_health)
				.map((a) => a._id);
			titular.addons = titular.addons.filter(
				(a) => !addonsHealth.includes(a._id)
			);
			titular.addons_id = titular.addons_id.filter(
				(a) => !addonsHealth.includes(a.addon_id || a)
			);
			this.props.saveState('titular', titular);
		}
	};

	setQuestionId = (questions, surveyID, questionID) => {
		if (!questions) return questions;
		return questions.map((question) => {
			if (question.case === undefined && question.survey === undefined) {
				return {
					...question,
					value: '',
					surveys: this.setQuestionId(question.surveys, surveyID, question._id),
					surveyID: surveyID,
					questionID: question._id
				};
			}
			return {
				case: question.case,
				surveys: this.setQuestionId(question.survey, surveyID, questionID)
			};
		});
	};

	handleSelectPlan = async (product) => {
		let titular = this.props.titular;
		titular.product_id = product._id;
		titular.product_type = product;
		titular.total = product.product_price;
		titular.questions = product.questions;
		titular.answers = this.setQuestionId(product.questions, product.surveyID);

		if (this.props.titular.include_titular === true) {
			titular.addons_id = [];
			titular.addons = [];
			titular.goodHealth = false;
			const result = await this.props.fetchAddons(titular);
			if (result) titular.listAddons = result.addons;
		}

		this.props.saveState('titular', titular);
		this.setState({ showInfo: '' });
	};

	handleRadioInputQuestions = async (event, index, _id, answer) => {
		let { name, value } = event.target;
		let { titular } = this.props;
		let showSub;
		let indexValue;

		if (answer.isBoolean) {
			indexValue = 'value';
			value = value === 'true';
			if (answer.validValue === undefined) showSub = value;
			else showSub = value !== answer.validValue;
		}
		if (answer.isRange) {
			indexValue = 'valueInRange';
			value = parseFloat(value) || 0;
			showSub = value > answer.minValue && value < answer.maxValue;
			titular.requireMedicalVerification = value > answer.maxValue;
		}
		if (answer.isSelectMultiple) {
			indexValue = 'selectedIndex';

			if (!Array.isArray(value)) value = [];
			value = value
				.concat(value._id)
				.filter((v) => v)
				.map((v) => v._id);
			titular.requireMedicalVerification = value.length >= 3;

			if (value.some((v) => v === 'otros' || v === 'Otros' || v === 'OTROS')) {
				showSub = 'otros';
			} else {
				showSub = '';
			}
		}
		if (answer.isString) {
			indexValue = 'value_string';
			showSub = '';
		}

		const setAnswerValue = (subMenuItems, id, current, top) => {
			if (!subMenuItems) return;
			if (subMenuItems.length === 0) return;

			for (let i = 0; i < subMenuItems.length; i += 1) {
				if (subMenuItems[i].case === undefined) {
					if (subMenuItems[i].questionID === id) {
						if (subMenuItems[i].surveys?.length > 0) top = current;
						subMenuItems[i][indexValue] = value;
						subMenuItems[i].showSub = showSub;
					} else if (current > top) {
						subMenuItems[i].value = '';
						delete subMenuItems[i].showSub;
						delete subMenuItems[i].valueInRange;
						delete subMenuItems[i].selectedIndex;
						delete subMenuItems[i].value_string;
					}
				}
				setAnswerValue(subMenuItems[i].surveys, id, ++current, top);
			}
		};
		setAnswerValue(titular.answers, _id, 0, 999);

		const isIncomplete = surveysCompleted(titular[name]);
		if (
			isIncomplete ||
			titular.body_mass ||
			titular.weight ||
			titular.feet ||
			titular.inches
		)
			titular.goodHealth = false;
		if (!isIncomplete) {
			titular.complete_surveys = true;
			if (titular.answers?.length > 0) {
				await this.getTitularHealth();
				this.cleanAddonsHealth();
				// titular.addons_id = [];
				// titular.addons = [];
				const result = await this.props.fetchAddons(titular);
				if (result) {
					titular.listAddons = result.addons;
					this.props.saveState('titular', titular);
				}
			}
		} else {
			titular.goodHealth = false;
		}

		// const warning = await handleHealthWaning(titular[name]);
		// titular.show_warning = (warning) ? true : false;

		this.props.saveState('titular', titular);
		this.setState({ showInfo: '' });
		if (
			titular.include_titular === true &&
			this.props.is_profile !== true &&
			!this.props.readOnlyPlans
		)
			this.props.calculations();
	};

	handleSelectAddon = (flag, value, idx) => {
		const { titular } = this.props;
		if (titular && !titular.addons_id) titular.addons_id = [];
		const idxToDlt = titular.addons_id.findIndex(
			(a) => (a?.addon_id || a) === value._id
		);
		titular.addons = getUserAddons(titular);

		if (flag && idxToDlt < 0) {
			if (titular && !titular.addons_id) titular.addons_id = [];
			titular.addons.push(value);
			titular.addons_id.push({
				addon_id: value._id,
				addon_price: value.payment_methods[0].tariffs[0].price
			});
		} else if (!flag && idxToDlt >= 0) {
			titular.addons = titular.addons.filter((a) => a._id !== value._id);
			titular.addons_id = titular.addons_id.filter(
				(a) => (a?.addon_id || a) !== value._id
			);
		}

		this.props.saveState('titular', titular);
		this.setState({ showInfo: '' });
	};

	titular_inputs(titular) {
		const {
			is_edit,
			show_in_step_two,
			is_profile,
			getTitularDocument,
			isAlly,
			isAllyMaster,
			isAllyMatrix,
			isAdmin,
			isLeadOperator,
			isCoordinator,
			token,
			updating = false,
			user_type,
			isSuper,
			no_offers_coupons,
			modeAdmin,
			readOnlyPlans,
			handleChangePassword,
			customers,
			isAdminOrAlly,
			is_ally_client,
			isoCode
		} = this.props;

		const sexs = [
			{
				value: 'M',
				name: 'Masculino'
			},
			{
				value: 'F',
				name: 'Femenino'
			}
		];
		const nacionalities = [
			{
				value: 'Cédula',
				name: 'Cédula'
			},
			{
				value: 'Pasaporte',
				name: 'Pasaporte'
			}
		];

		const isMobile = Router?.router?.asPath?.includes('/m');

		return (
			<div className="flex flex-wrap w-full">
				{is_edit !== true &&
					show_in_step_two !== false &&
					is_profile !== true && (
						<span className="text-2xl text-gray-800 font-poppins font-bold">
							Ingresa los datos del titular
						</span>
					)}
				<div className="flex flex-wrap mt-4 headline-form">
					<TextInput
						name="first_name"
						label="Nombres"
						placeholder="Nombres"
						helperText={titular.formErrors?.first_name}
						errors={titular.formErrors?.first_name}
						value={titular.first_name}
						addClass="wInputThree"
						onChange={this.handleInput}
						required={true}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={titular.NotEditCustomer}
					/>

					<TextInput
						name="last_name"
						label="Apellidos"
						placeholder="Apellidos"
						helperText={titular.formErrors?.last_name}
						errors={titular.formErrors?.last_name}
						value={titular.last_name}
						addClass="wInputThree"
						onChange={this.handleInput}
						required={true}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={titular.NotEditCustomer}
					/>

					<SelectSimpleOutlined
						name="sex"
						label="Sexo"
						value={titular.sex}
						placeholder="Selecciona el sexo"
						listItems={sexs}
						addClass={`wInputThree ${titular.sex === 'default' && 'default'}`}
						helperText={titular.formErrors?.sex}
						errors={titular.formErrors?.sex}
						onChange={this.handleInputSex}
						required={true}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={titular.NotEditCustomer}
					/>

					{/* Identifier... */}
					<SelectSimpleOutlined
						name="nationality"
						label="Tipo de Documento"
						value={titular.nationality}
						placeholder="Selecciona el tipo"
						listItems={nacionalities}
						addClass={`wInputThree ${
							titular.nationality === 'default' && 'default'
						}`}
						helperText={titular.formErrors?.nationality}
						errors={titular.formErrors?.nationality}
						onChange={this.handleInput}
						required={true}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={titular.NotEditCustomer}
					/>

					<TextInput
						name="dni"
						label="Número de Documento"
						placeholder="Número de Documento"
						helperText={titular.formErrors?.dni}
						errors={titular.formErrors?.dni}
						value={titular.dni}
						addClass="wInputThree"
						onChange={this.handleInput}
						required={true}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={
							(customers && customers.length > 0
								? true
								: updating === true && !isAdmin
								? true
								: false) || titular.NotEditCustomer
						}
					/>

					<FileWithIconInput
						name="document_dni"
						label="Documento de identidad"
						required={true}
						value={titular.document_dni}
						ifExist={titular.document_dni && getTitularDocument ? true : false}
						showView={titular.document_dni === 'Archivo cargado' ? true : false}
						placeholder="Seleccione el documento de identidad"
						addClass="wInputThree"
						changeClass={false}
						errors={titular.formErrors?.doc_dni}
						helperText={titular.formErrors?.doc_dni}
						onChange={(e) => this.handleInputFile(e)}
						onClick={() => getTitularDocument(titular._id)}
						disabled={titular.NotEditCustomer}
					/>

					{titular?.include_titular === true && (
						<TextInput
							name="occupation"
							label="Ocupación"
							placeholder="Ocupación"
							helperText={titular.formErrors?.occupation}
							errors={titular.formErrors?.occupation}
							value={titular.occupation}
							addClass="wInputThree"
							onChange={this.handleInput}
							required={true}
							FormHelperTextProps={{
								classes: {
									root: is_profile === true ? '' : 'cssHelperTextError'
								}
							}}
							disabled={titular.NotEditCustomer}
						/>
					)}

					<DateInput
						name="birth_date"
						label="Fecha de Nacimiento"
						placeholder="Fecha de Nacimiento"
						required={!isAlly && !isAllyMaster && !isAllyMatrix}
						maxDate={
							new Date(new Date().setFullYear(new Date().getFullYear() - 18))
						}
						helperText={titular.formErrors?.birth_date}
						errors={titular.formErrors?.birth_date}
						value={titular.birth_date}
						addClass="wInputThree"
						onChange={(date) => this.handleBirthDate(date)}
						disabled={titular.NotEditCustomer}
					/>

					<Tooltip
						open={
							Router.router.pathname === '/profile' &&
							this.state.isChangeEmail &&
							!titular.email_updated &&
							!titular.email_confirm &&
							titular.mode === 'email'
						}
						title="Nota: Verifica que el correo sea el correcto, ya que solo podrás cambiarlo una vez."
						placement="top"
						arrow
					>
						<TextInput
							name="email"
							label="Correo electrónico"
							placeholder="Correo electrónico"
							type="email"
							helperText={titular.formErrors?.email}
							errors={titular.formErrors?.email}
							value={titular.email}
							addClass="wInputThree"
							onChange={this.handleInput}
							required={true}
							FormHelperTextProps={{
								classes: { root: is_profile ? '' : 'cssHelperTextError' }
							}}
							disabled={
								((isAdmin || isSuper || isLeadOperator || isCoordinator) &&
								updating === false
									? false
									: (token && user_type === 1 && titular.email_updated) ||
									  (token &&
											titular.email_updated &&
											(isAlly || isAllyMaster || isAllyMatrix)) ||
									  (token && titular.email_updated && updating === true) ||
									  (token && titular.email_confirm) ||
									  (token && titular.mode !== 'email') ||
									  updating === true
									? true
									: false) || titular.NotEditCustomer
							}
						/>
					</Tooltip>

					<ReactTelInput
						required={true}
						onChange={(telNumber) => this.handlePhone('phone_one', telNumber)}
						value={titular.phone_one}
						defaultCountry="do"
						flagsImagePath={Flags}
						// className={(!titular.phone_one) ? 'telInput' : (titular.formErrors?.phone_one) ? 'telInputError' : "telInput"}
						className={
							titular.phone_one && !titular.formErrors?.phone_one
								? 'telInput'
								: (!titular.phone_one && titular.formErrors?.phone_one) ||
								  titular.formErrors?.phone_one
								? 'telInputError'
								: 'telInput'
						}
						disabled={titular.NotEditCustomer}
					/>

					<ReactTelInput
						required={true}
						onChange={(telNumber) => this.handlePhone('phone_two', telNumber)}
						value={titular.phone_two}
						defaultCountry="do"
						flagsImagePath={Flags}
						// className={(!titular.phone_two) ? 'telInput' : (titular.formErrors?.phone_two) ? 'telInputError' : "telInput"}
						className={
							titular.phone_two && !titular.formErrors?.phone_two
								? 'otherLabel telInput'
								: (!titular.phone_two && titular.formErrors?.phone_two) ||
								  titular.formErrors?.phone_two
								? 'otherLabel telInputError'
								: 'otherLabel telInput'
						}
						disabled={titular.NotEditCustomer}
					/>

					<TextField
						select
						name="country"
						label="País de residencia"
						helperText={titular.formErrors?.country}
						variant="outlined"
						className={`wInputThree ${
							titular.country === 'default' && 'default'
						}`}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						InputLabelProps={{
							classes: {
								root:
									titular.country && !titular.formErrors?.country
										? 'cssLabel'
										: (!titular.country && titular.formErrors?.country) ||
										  titular.formErrors?.country
										? 'cssLabelError'
										: 'cssLabelSuccess'
							},
							shrink: true
						}}
						InputProps={{
							classes: {
								notchedOutline:
									!titular.country && !titular.formErrors?.country
										? 'cssInput'
										: (!titular.country && titular.formErrors?.country) ||
										  titular.formErrors?.country
										? 'cssInputError'
										: 'cssInputSuccess'
							}
						}}
						SelectProps={{
							IconComponent: KeyboardArrowDownIcon
						}}
						margin="normal"
						error={true}
						value={titular.country || ''}
						required={!isAlly && !isAllyMaster && !isAllyMatrix}
						onChange={this.handleInputCountry}
						disabled={titular.NotEditCustomer}
					>
						<MenuItem value="default" hidden disabled>
							Selecciona el país
						</MenuItem>
						{titular.include_titular === true && (
							<MenuItem value="República Dominicana">
								República Dominicana
							</MenuItem>
						)}
						{(titular.include_titular === false ||
							!titular.include_titular ||
							is_profile === true ||
							isAdmin) &&
							CountryList.map((country, i) => (
								<MenuItem key={i} value={country.name}>
									{country.name}
								</MenuItem>
							))}
					</TextField>

					{(titular.include_titular === false ||
						!titular.include_titular ||
						titular?.country !== 'República Dominicana') && (
						<TextInput
							name="city"
							label="Provincia"
							placeholder="Provincia"
							helperText={titular.formErrors?.city}
							errors={titular.formErrors?.city}
							value={titular.city}
							addClass="wInputThree"
							onChange={this.handleInput}
							required={true}
							FormHelperTextProps={{
								classes: {
									root: is_profile === true ? '' : 'cssHelperTextError'
								}
							}}
							disabled={titular.NotEditCustomer}
						/>
					)}

					{titular.include_titular === true &&
						titular?.country === 'República Dominicana' && (
							<TextField
								select
								name="city"
								label="Provincia"
								placeholder="Provincia"
								helperText={titular.formErrors?.city}
								variant="outlined"
								error={true}
								className={`wInputThree ${
									titular.city === 'default' && 'default'
								}`}
								FormHelperTextProps={{
									classes: {
										root: is_profile === true ? '' : 'cssHelperTextError'
									}
								}}
								InputLabelProps={{
									classes: {
										root:
											titular.city && !titular.formErrors?.city
												? 'cssLabel'
												: (!titular.city && titular.formErrors?.city) ||
												  titular.formErrors?.city
												? 'cssLabelError'
												: 'cssLabelSuccess'
									},
									shrink: true
								}}
								InputProps={{
									classes: {
										notchedOutline:
											!titular.city && !titular.formErrors?.city
												? 'cssInput'
												: (!titular.city && titular.formErrors?.city) ||
												  titular.formErrors?.city
												? 'cssInputError'
												: 'cssInputSuccess'
									}
								}}
								SelectProps={{
									IconComponent: KeyboardArrowDownIcon
								}}
								margin="normal"
								value={titular.city || ''}
								required={!isAlly && !isAllyMaster && !isAllyMatrix}
								onChange={(event) => this.handleInput(event)}
								disabled={titular.NotEditCustomer}
							>
								<MenuItem value="default" hidden disabled>
									Selecciona la provincia
								</MenuItem>
								{CityList.map(({ name, value }) => (
									<MenuItem key={name} value={value}>
										{name}
									</MenuItem>
								))}
							</TextField>
						)}

					{titular.include_titular === true &&
						<TextInput
							name="city_two"
							label="Ciudad"
							placeholder="Ciudad"
							helperText={titular.formErrors?.city_two}
							errors={titular.formErrors?.city_two}
							value={titular?.city_two}
							addClass="wInputThree"
							onChange={this.handleInput}
							required={true}
							FormHelperTextProps={{ classes: { root: is_profile === true ? '' : 'cssHelperTextError'}}}
						/>
					}

					<TextInput
						name="address"
						label="Dirección residencia"
						placeholder="Dirección residencia"
						helperText={titular.formErrors?.address}
						errors={titular.formErrors?.address}
						value={titular.address}
						addClass="wInputThree"
						onChange={this.handleInput}
						required={
							isSecureContext && !isAlly && !isAllyMaster && !isAllyMatrix
						}
						FormHelperTextProps={{
							classes: { root: is_profile === true ? '' : 'cssHelperTextError' }
						}}
						disabled={titular.NotEditCustomer}
					/>

					{is_profile === true && (
						<Tooltip
							title={
								titular.status
									? 'Si no posee contraseña, puede crearla aquí'
									: 'No puede cambiar la contraseña porque su usuario aun no ha sido activado'
							}
						>
							<TextField
								name="password"
								label="Contraseña"
								placeholder="********"
								type="text"
								helperText={titular.formErrors?.password}
								variant="outlined"
								className="wInputThree"
								FormHelperTextProps={{
									classes: {
										root: is_profile === true ? '' : 'cssHelperTextError'
									}
								}}
								InputLabelProps={{
									classes: {
										root: 'cssLabel'
									},
									shrink: true
								}}
								InputProps={{
									classes: {
										notchedOutline: 'cssInput'
									},
									endAdornment: (
										<InputAdornment position="end">
											<button
												className={`${
													titular.status ? 'text-pink-500' : 'text-gray-500'
												} text-small tracking-extra cursor-pointer text-center font-bold font-poppins uppercase`}
												aria-label="change password"
												onClick={() => handleChangePassword(true)}
												disabled={!titular.status}
											>
												CAMBIAR
											</button>
										</InputAdornment>
									)
								}}
								margin="normal"
								value=""
								disabled={true}
								onChange={this.handleInput}
							/>
						</Tooltip>
					)}

					{(isAlly || isAllyMaster || isAllyMatrix) &&
						!no_offers_coupons === true &&
						modeAdmin === true &&
						is_profile !== true && (
							<div className="flex w-full">
								<FormControlLabel
									className="nm w-60"
									label={
										<span
											className={`text-gray-800 text-base ${
												titular.promo_code === true
													? 'font-bold'
													: 'font-normal'
											}`}
										>
											Otorgar cupón de 5% de descuento
										</span>
									}
									control={
										<Checkbox
											name="promo_code"
											required={true}
											className={
												titular.promo_code === true
													? 'checkBoxActive'
													: 'checkBox'
											}
											onClick={(e) =>
												this.handleCheckbox(
													e,
													titular.promo_code === true ? false : true
												)
											}
											checked={titular.promo_code || false}
											disabled={titular.NotEditCustomer}
										/>
									}
								/>
							</div>
						)}
					{(isAlly || isAllyMaster || isAllyMatrix) &&
						modeAdmin === true &&
						is_profile !== true && (
							<div className="flex w-full">
								<FormControlLabel
									className="nm w-60"
									label={
										<span
											className={`text-gray-800 text-base ${
												titular.assisted_selling === true
													? 'font-bold'
													: 'font-normal'
											}`}
										>
											Quiero que un Big Broder impulse la afiliación
											<Tooltip title="Quiero que un Big Broder impulse la afiliación por tu Big Broder">
												<span className="font-bold text-purple-500"> (?)</span>
											</Tooltip>
										</span>
									}
									control={
										<Checkbox
											name="assisted_selling"
											required={true}
											className={
												titular.assisted_selling === true
													? 'checkBoxActive'
													: 'checkBox'
											}
											onClick={(e) =>
												this.handleCheckboxAssistedSelling(
													e,
													titular.assisted_selling === true ? false : true
												)
											}
											checked={titular.assisted_selling || false}
											disabled={titular.NotEditCustomer}
										/>
									}
								/>
							</div>
						)}

					{titular.assisted_selling && (
						<div className="bg-purple-200 p-3 rounded mb-5">
							<p>
								Con esta opción uno de nuestros asesores comerciales contactará
								al titular para explicar en detalle el producto, responder sus
								dudas e impulsar la afiliación. Nosotros nos encargamos de
								concretar la afiliación y ¡tú recibes el pago por referido!
							</p>
						</div>
					)}
				</div>
				{((is_profile !== true &&
					titular.include_titular === true &&
					!isAdmin) ||
					(is_profile !== true &&
						titular.include_titular === true &&
						isAdmin &&
						titular.self_paid)) && (
					<div className="select-plan-cont px-5 hidden md:flex">
						<span className="text-gray-800 text-base font-bold leading-relaxed">
							Planes de Salud
						</span>
						<div className="flex justify-between w-2/5">
							{titular.birth_date &&
								titular.products &&
								titular.products.length > 0 &&
								titular.products.map((product, i) => (
									<FormControlLabel
										key={i}
										name="product"
										className="m-r10"
										value={String(product._id)}
										control={
											<Radio
												value={String(product._id)}
												className={
													titular.product_id === product._id
														? 'checkBoxActive'
														: 'checkBox'
												}
												checked={
													titular.product_id === product._id ? true : false
												}
											/>
										}
										onChange={() => this.handleSelectPlan(product)}
										label={
											<span
												className={`text-gray-800 text-normal ${
													titular.product_id === product._id
														? 'font-bold'
														: 'font-normal'
												}`}
											>
												{product.name}
											</span>
										}
										labelPlacement="end"
										disabled={!!readOnlyPlans || titular.NotEditCustomer}
									/>
								))}
							{!titular.birth_date && (
								<span className="text-gray-600 text-normal my-2">
									Debe ingresar su fecha de nacimiento
								</span>
							)}
						</div>
						<span className="text-normal leading-relaxed mr-2">
							{titular.total && titular?.product_type ? (
								<span className={`text-gray-800 ${isMobile ? 'text-xs' : 'text-sm'}`}>
									{isoCode && isoCode.toUpperCase()}{' '}
									{formatFloat(titular?.product_type?.currency[isoCode])} anual
								</span>
							) : (
								<span className="text-gray-600">
									{isoCode && isoCode.toUpperCase()} 0
								</span>
							)}
						</span>
						<span className="ml-2">
							<SelectCurrency hideFlag={true} />
						</span>
					</div>
				)}
				{/* RESPONSIVE PLANS */}
				{((is_profile !== true &&
					titular.include_titular === true &&
					!isAdmin) ||
					(is_profile !== true &&
						titular.include_titular === true &&
						isAdmin &&
						titular.self_paid)) && (
					<div className="w-full border border-gray-300 mt-6 mb-2 bg-white md:hidden">
						<div className="flex items-center bg-white border-b border-gray-300 min-h-50p px-5">
							<span className="text-gray-800 text-normal font-bold">
								Selecciona el plan
							</span>
						</div>
						<div className="text-gray-800 text-normal flex justify-between p-5 border-b border-gray-300">
							<span className="font-bold">Plan</span>
							<div className="flex flex-col items-end">
								{titular.products &&
									titular.products.length > 0 &&
									titular.products.map((product, i) => (
										<span key={i} className="m-b5">
											<FormControlLabel
												key={i}
												name="product"
												value={String(product._id)}
												control={
													<Radio
														value={String(product._id)}
														className={
															titular.product_id === product._id
																? 'checkBoxActive'
																: 'checkBox'
														}
														checked={
															titular.product_id === product._id ? true : false
														}
													/>
												}
												onChange={() => this.handleSelectPlan(product)}
												label={
													<span
														className={`text-gray-800 text-normal ${
															titular.product_id === product._id
																? 'font-bold'
																: 'font-normal'
														}`}
													>
														{product.name}
													</span>
												}
												labelPlacement="start"
												disabled={!!readOnlyPlans || titular.NotEditCustomer}
											/>
										</span>
									))}
							</div>
						</div>
						<div className="text-gray-800 text-normal flex items-center justify-between px-5 min-h-50p">
							<span className="font-bold">Valor</span>
							<span>
								{titular.total ? (
									<span className={`text-gray-800 ${isMobile ? 'text-xs' : 'text-sm'}`}>
										{isoCode && isoCode.toUpperCase()}{' '}
										{formatFloat(titular?.product_type?.currency[isoCode])}{' '}
										anual
									</span>
								) : (
									<span className="text-gray-600">
										{isoCode && isoCode.toUpperCase()} 0
									</span>
								)}
								<span className="ml-2">
									<SelectCurrency hideFlag={true} />
								</span>
							</span>
						</div>
					</div>
				)}
				{((titular.product_id &&
					is_profile !== true &&
					titular.include_titular === true &&
					!isAdmin) ||
					(titular.product_id &&
						is_profile !== true &&
						titular.include_titular === true &&
						isAdmin &&
						titular.self_paid)) &&
					!titular.NotEditCustomer &&
					(!isAdminOrAlly || (isAdminOrAlly && is_ally_client)) && (
						<HealthSurvey
							is_titular={true}
							questions={titular?.questions}
							parent={titular}
							handleRadioInputQuestions={this.handleRadioInputQuestions}
							readOnlyPlans={readOnlyPlans}
						/>
					)}
				{((titular.product_id &&
					is_profile !== true &&
					titular.include_titular === true &&
					!isAdmin) ||
					(titular.product_id &&
						is_profile !== true &&
						titular.include_titular === true &&
						isAdmin &&
						titular.self_paid)) && (
					<div className="flex flex-col mb-2 mt-10 w-full">
						<div className="flex flex-wrap">
							<SelectSimpleOutlined
								name="feet"
								label="Estatura"
								value={titular.feet}
								placeholder="Pies"
								simpleList={['0', '1', '2', '3', '4', '5', '6', '7', '8']}
								addClass={`wInputThree ${
									titular.feet === 'default' && 'default'
								}`}
								helperText={titular.formErrors?.feet}
								errors={titular.formErrors?.feet}
								onChange={this.handleInput}
								required={true}
								FormHelperTextProps={{
									classes: {
										root: is_profile === true ? '' : 'cssHelperTextError'
									}
								}}
								prefix="(ft)"
								disabled={titular.NotEditCustomer}
							/>

							<SelectSimpleOutlined
								name="inches"
								label="Estatura"
								value={titular.inches}
								placeholder="Pulgadas"
								simpleList={[
									'0',
									'1',
									'2',
									'3',
									'4',
									'5',
									'6',
									'7',
									'8',
									'9',
									'10',
									'11'
								]}
								addClass={`wInputThree ${
									titular.inches === 'default' && 'default'
								}`}
								helperText={titular.formErrors?.inches}
								errors={titular.formErrors?.inches}
								onChange={this.handleInput}
								required={true}
								FormHelperTextProps={{
									classes: {
										root: is_profile === true ? '' : 'cssHelperTextError'
									}
								}}
								prefix="(in)"
								disabled={titular.NotEditCustomer}
							/>

							<TextInput
								type="number"
								name="weight"
								label="Peso"
								placeholder="Peso"
								helperText={titular.formErrors?.weight}
								errors={titular.formErrors?.weight}
								error={titular.formErrors?.weight ? true : false}
								value={titular.weight}
								addClass="wInputThree"
								onChange={this.handleInput}
								FormHelperTextProps={{
									classes: {
										root: is_profile === true ? '' : 'cssHelperTextError'
									}
								}}
								disabled={!!readOnlyPlans || titular.NotEditCustomer}
								prefix="(lb)"
							/>
						</div>
					</div>
				)}
				{/* ADDONS */}
				{!this.props.is_profile &&
					titular.include_titular === true &&
					titular?.listAddons?.length > 0 && (
						<AddonsCard
							isoCode={isoCode}
							user={titular}
							handleSelectAddon={this.handleSelectAddon}
						/>
					)}
				<SnackBar
					openAlert={this.state.openAlert}
					messageAlert={this.state.messageAlert}
					handleClose={this.handleClose}
				/>
			</div>
		);
	}

	render() {
		return <div>{this.titular_inputs(this.props.titular)}</div>;
	}
}

export default HeadlineForm;
