import React from 'react';
import PdfViewer from '../pdfViewer/PdfViewer';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions
} from '@material-ui/core';

const logo = '/static/images/Asistensi_logotipo.svg';

const TermsAddonDisplayer = ({
    addon,
    handleClose,
	termsConditions=[]
}) => {
	return (
		<Dialog
			open={open}
			maxWidth="md"
			fullWidth={true}
			onClose={handleClose}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
			fullScreen={false}
		>
			<DialogTitle className="text-gray-900 font-bold">
				<img loading="lazy" src={logo} alt="asistensi_logotipo" width="160px" />
			</DialogTitle>

			<DialogContent>
				<div>
					<PdfViewer url={addon.link_tc} />
				</div>
			</DialogContent>
			
			<DialogActions>	
			<div className="flex justify-evenly my-10 w-full">
				<button
					className="secondary-button-nav mr-2"
					onClick={() => handleClose(false, 'acceptTerms')}
				>
					No acepto
				</button>
				<button
					className="white-button-nav"
					onClick={() => handleClose(true, 'acceptTerms')}
				>
					Acepto
				</button>
			</div>
		</DialogActions>
		</Dialog>	
	);
};


export default withMobileDialog({ breakpoint: 'xs' })(TermsAddonDisplayer);

