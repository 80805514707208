import React from 'react';

import {
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	Input,
	Select,
	InputLabel,
	MenuItem
} from '@material-ui/core';

import { TextInput, SelectOutlined } from 'components/commons';

function HealthSurvey({
	questions,
	parent,
	handleRadioInputQuestions,
	isAlly,
	index,
	readOnlyPlans,
	is_titular,
	answers,
	classes,
	questionClass,
	edit
}) {
	const findItemNested = (arr, itemId, nestingKey) => {
		return arr.reduce((a, item) => {
			if (a) return a;
			if (item.questionID && item.questionID === itemId) return item;
			if (item[nestingKey])
				return findItemNested(item[nestingKey], itemId, nestingKey);
		}, null);
	};

	const renderQuestion = (qs, sub, questionID) => {
		const qId = questionID;
		parent.NotEditCustomer = edit ? false : parent.NotEditCustomer;

		return qs?.map((q, idx) => {
			const ID = q._id || qId;
			const answer =
				answers && answers.length > 0
					? findItemNested(answers, ID, 'surveys')
					: findItemNested(parent.answers, ID, 'surveys');

			return (
				// <div className={`${sub ? 'w-full px-3' : 'w-1/2 p-3'} self-start`} key={idx}>
				<div
					className="w-full px-3 self-start bg-gray-100 p-3 mb-3 rounded"
					key={idx}
				>
					<div className="flex w-full">
						<span className="text-gray-800 font-bold text-normal">
							{q.name}
						</span>
					</div>
					{q.help && (
						<div className="bg-purple-200 p-5 text-purple-500 text-sm leading-relaxed mt-4">
							{q.help}
						</div>
					)}
					{q.isBoolean && (
						<>
							<div className="w-full my-5">
								<RadioGroup
									aria-label={q.name}
									name="answers"
									value={String(answer?.value)}
									className={`text-sm flex items-center w-full flex-row m-2`}
									onChange={(e) =>
										handleRadioInputQuestions(e, index, q._id, answer)
									}
									required={isAlly ? false : true}
									disabled={!!readOnlyPlans || parent.NotEditCustomer}
								>
									<FormControlLabel
										value="true"
										control={
											<Radio
												value={true}
												className={
													answer?.value === true ? 'checkBoxActive' : 'checkBox'
												}
												checked={answer?.value === true ? true : false}
											/>
										}
										label={q.yes || 'Si'}
										labelPlacement="end"
										disabled={!!readOnlyPlans}
									/>
									<FormControlLabel
										value="false"
										control={
											<Radio
												value={false}
												className={
													answer?.value === false
														? 'checkBoxActive'
														: 'checkBox'
												}
												checked={answer?.value === false ? true : false}
											/>
										}
										label={q.not || 'No'}
										labelPlacement="end"
										disabled={!!readOnlyPlans}
									/>
								</RadioGroup>
							</div>
							{q.surveys?.length > 0 &&
								renderQuestion(
									q.surveys.find((s) => s.case === answer?.showSub?.toString())
										?.survey,
									true,
									ID
								)}
						</>
					)}
					{q.isRange && (
						<>
							<div className="w-full my-5">
								<TextInput
									type="number"
									name="answers"
									label="Introduzca el valor"
									placeholder="Valor"
									value={answer?.valueInRange}
									addClass="wInputFull"
									onChange={(e) =>
										handleRadioInputQuestions(e, index, q._id, answer)
									}
									disabled={parent.NotEditCustomer}
									required={false}
								/>
							</div>
							{q.surveys?.length > 0 &&
								renderQuestion(
									q.surveys.find((s) => s.case === answer?.showSub?.toString())
										?.survey,
									true,
									ID
								)}
						</>
					)}
					{q.isSelectMultiple && (
						<>
							<SelectOutlined
								multiple={true}
								name="answers"
								label="Medicamentos"
								addClass="wInputFull "
								// value={Array.isArray(answer?.selectedIndex) ? answer?.selectedIndex?.map(s => s) : []}
								value={
									answer?.selectedIndex?.map((s) => ({ _id: s, name: s })) || []
								}
								listItems={
									q.selectList?.map((s) => ({ _id: s, name: s })) || []
								}
								onChange={(e) =>
									handleRadioInputQuestions(e, index, q._id, answer)
								}
								disabled={parent.NotEditCustomer}
							/>
							{q.surveys?.length > 0 &&
								renderQuestion(
									q.surveys.find((s) => s.case === answer?.showSub?.toString())
										?.survey,
									true,
									ID
								)}
						</>
					)}
					{q.isString && (
						<>
							<div className="w-full my-5">
								<TextInput
									name="answers"
									label="Especifique"
									value={answer?.value_string}
									addClass="wInputFull"
									onChange={(e) =>
										handleRadioInputQuestions(e, index, q._id, answer)
									}
									disabled={parent.NotEditCustomer}
									required={false}
								/>
							</div>
							{q.surveys?.length > 0 &&
								renderQuestion(
									q.surveys.find((s) => s.case === answer?.showSub?.toString())
										?.survey,
									true,
									ID
								)}
						</>
					)}
				</div>
			);
		});
	};

	const renderResponsiveQuestion = (qs, sub, questionID) => {
		const qId = questionID;
		parent.NotEditCustomer = edit ? false : parent.NotEditCustomer;

		return qs?.map((q, idx) => {
			const ID = q._id || qId;
			const answer =
				answers && answers.length > 0
					? findItemNested(answers, ID, 'surveys')
					: findItemNested(parent.answers, ID, 'surveys');

			return (
				<div
					className={`flex flex-wrap md:hidden flex-col w-full bg-white border border-gray-300 ${
						questionClass ? questionClass : 'mb-2'
					} ${sub ? 'px-2' : 'p-2'}`}
					key={idx}
				>
					<div className="w-full flex items-center bg-white border-b border-gray-300 min-h-60p p-5">
						<span className="text-gray-800 font-bold text-normal">
							{q.name}
						</span>
					</div>
					{q.help && (
						<div className="w-full bg-purple-200 p-5 text-purple-500 text-sm leading-relaxed">
							{q.help}
						</div>
					)}
					{q.isBoolean && (
						<>
							<div className="w-full mx-2">
								<RadioGroup
									aria-label={q.name}
									name="answers"
									value={String(answer?.value)}
									className={`text-sm flex items-center w-full flex-row m-2`}
									onChange={(e) =>
										handleRadioInputQuestions(e, index, q._id, answer)
									}
									required={isAlly ? false : true}
									// disabled={!!readOnlyPlans || parent.NotEditCustomer}
								>
									<FormControlLabel
										value="true"
										control={
											<Radio
												value={true}
												className={
													answer?.value === true ? 'checkBoxActive' : 'checkBox'
												}
												checked={answer?.value === true ? true : false}
											/>
										}
										label={q.yes || 'Si'}
										labelPlacement="end"
										disabled={!!readOnlyPlans || parent.NotEditCustomer}
									/>
									<FormControlLabel
										value="false"
										control={
											<Radio
												value={false}
												className={
													answer?.value === false
														? 'checkBoxActive'
														: 'checkBox'
												}
												checked={answer?.value === false ? true : false}
											/>
										}
										label={q.not || 'No'}
										labelPlacement="end"
										disabled={!!readOnlyPlans || parent.NotEditCustomer}
									/>
								</RadioGroup>
							</div>
							{q.surveys?.length > 0 &&
								renderQuestion(
									q.surveys.find((s) => s.case === answer?.showSub?.toString())
										?.survey,
									true,
									ID
								)}
						</>
					)}
					{q.isRange && (
						<>
							<div className="w-full px-2">
								<TextInput
									type="number"
									name="answers"
									label="Introduzca el valor"
									placeholder="Valor"
									value={answer?.value}
									addClass="wInputFull"
									onChange={(e) =>
										handleRadioInputQuestions(e, index, q._id, answer)
									}
									disabled={parent.NotEditCustomer}
								/>
							</div>
							{q.surveys?.length > 0 &&
								renderQuestion(
									q.surveys.find((s) => s.case === answer?.showSub?.toString())
										?.survey,
									true,
									ID
								)}
						</>
					)}
					{q.isSelectMultiple && (
						<>
							<SelectOutlined
								multiple={true}
								name="answers"
								label="Medicamentos"
								addClass="wInputFull px-2"
								value={
									Array.isArray(answer?.value)
										? answer?.value?.map((s) => s)
										: []
								}
								listItems={
									q.selectList?.map((s) => ({ _id: s, name: s })) || []
								}
								onChange={(e) =>
									handleRadioInputQuestions(e, index, q._id, answer)
								}
								disabled={parent.NotEditCustomer}
							/>
						</>
					)}
					{q.isString && (
						<>
							<div className="w-full px-2">
								<TextInput
									name="answers"
									label="Introduzca el valor"
									placeholder="Valor"
									value={answer?.value}
									addClass="wInputFull"
									onChange={(e) =>
										handleRadioInputQuestions(e, index, q._id, answer)
									}
									disabled={parent.NotEditCustomer}
								/>
							</div>
							{q.surveys?.length > 0 &&
								renderQuestion(
									q.surveys.find((s) => s.case === answer?.showSub.toString())
										?.survey,
									true,
									ID
								)}
						</>
					)}
				</div>
			);
		});
	};

	return (
		<>
			{/*<div className="select-plan-cont w-full hidden md:flex md:flex-wrap">*/}
			<div className={`select-plan-cont w-full hidden md:block p-3 ${classes}`}>
				{questions?.length > 0 && renderQuestion(questions, false)}
			</div>
			{/* RESPONSIVE QUESTIONS */}
			<>{questions?.length > 0 && renderResponsiveQuestion(questions, false)}</>
			{/*{
        parent.show_warning === true &&
        <span className="w-full text-purple-500 text-sm font-bold">¡Podrás continuar con la afiliación del servicio SIN la cobertura de atención emergencias médicas en clínicas!</span>
      }*/}
		</>
	);
}

export default HealthSurvey;
