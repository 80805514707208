export const includeMsg =
	'Este beneficiario podrá continuar con la afiliación del plan de cobertura para emergencias médicas en centros de salud.';

export const notProcessingMsg =
	'No se procesará su suscripción en este momento';

export const excludingMsg = {
	primary:
		'Debido a su situación de salud, podrá continuar con la afiliación del servicio SIN la cobertura para emergencias médicas en centros de salud.'
};

export const diabetesMsgSupport = {
	primary:
		'Nos has indicado que este beneficiario padece de diabetes. El mismo podrá continuar con la afiliación del plan con la cobertura para emergencias médicas en centros de salud siempre y cuando adjunte el soporte de la prueba HbA1c en el perfil del beneficiario una vez completada la afiliación.',
	second:
		'Adicionalmente, debes tener en cuenta que las emergencias y urgencias médicas que surjan a raíz de la diabetes y/o hipertensión arterial no tendrán cobertura para emergencias médicas en centros de salud.'
};

export const htaMsgVerification = {
	primary:
		'Nos has indicado que este beneficiario padece de hipertensión arterial. Para poder confirmar la afiliación del plan con la cobertura para emergencias médicas en centros de salud debemos ponernos en contacto contigo. Esta afiliación quedará en pausa hasta que validemos cierta información, para ello le contactaremos vía telefónica al número de teléfono registrado.',
	second:
		'No obstante, debes tener en cuenta que las emergencias y urgencias médicas que surjan a raíz de la hipertensión arterial no tendrán cobertura para emergencias médicas en centros de salud.'
};

export const diabetesMsgVerification = {
	primary:
		'Nos has indicado que este beneficiario padece de diabetes. Para poder confirmar la afiliación del plan con la cobertura para emergencias médicas en centros de salud debemos ponernos en contacto contigo. Esta afiliación quedará en pausa hasta que validemos cierta información, para ello le contactaremos vía telefónica al número de teléfono registrado.',
	second:
		'No obstante, debes tener en cuenta que las emergencias y urgencias médicas que surjan a raíz de la diabetes no tendrán cobertura para emergencias médicas en centros de salud.'
};

export const diabetesHtaMsgSupport = {
	primary:
		'Nos has indicado que este beneficiario padece de hipertensión arterial y diabetes. El mismo podrá continuar con la afiliación del plan con la cobertura para emergencias médicas en centros de salud siempre y cuando adjunte el soporte de la prueba HbA1c en el perfil del beneficiario una vez completada la afiliación.',
	second:
		'No obstante, debes tener en cuenta que las emergencias y urgencias médicas que surjan a raíz de la diabetes y la hipertensión arterial no tendrán cobertura para emergencias médicas en centros de salud.'
};
